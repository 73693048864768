import { Idiomorph } from "idiomorph/dist/idiomorph.esm";
import RestoreDynamicFormValues from "./restore_dynamic_form_values";
import DirtyTracker from "./dirty_tracker";

export default class MorphableForm {
  constructor({ restorer = new RestoreDynamicFormValues(), dirtyFields = new DirtyTracker() }) {
    this.restoreDynamicFormValues = restorer;
    this.dirtyFields = dirtyFields;
  }

  morph(currentElement, newElement) {
    newElement.querySelectorAll("[autofocus]")
      .forEach((elm) => {
        elm.removeAttribute("autofocus");
      });
    Idiomorph.morph(
      currentElement, newElement.childNodes, {
        morphStyle: "innerHTML",
        ignoreActiveValue: true,
        callbacks: {
          beforeNodeRemoved: (element) => {
            if (element instanceof Text) { return; }
            if (element.closest("[data-dynamic-form-ignore-morph]")) { return false; }

            this.restoreDynamicFormValues.elementRemoved(element);
          },
          beforeAttributeUpdated: (attributeName, node) => {
            if (
              (node instanceof HTMLInputElement) &&
              this.dirtyFields.includes(attributeName, node.name)
            ) {
              return false;
            }
          },
          beforeNodeMorphed: (oldNode, newNode) => {
            if (oldNode instanceof Text) { return; }
            if (oldNode.closest("[data-dynamic-form-ignore-morph]")) { return false; }

            if ((newNode instanceof HTMLTextAreaElement)) {
              if (this.dirtyFields.includes("value", oldNode.name)) {
                newNode.innerHTML = oldNode.innerHTML;
              }
            }
            if (newNode instanceof HTMLSelectElement) {
              const oldValue = CSS.escape(oldNode.value);
              const valueStillExists = newNode.querySelector(
                `[value="${oldValue}"]`,
              );
              if (this.dirtyFields.includes("value", newNode.name) && valueStillExists) {
                newNode.value = oldNode.value;
                /* eslint-disable-next-line  no-restricted-syntax */
                for (const option of newNode.options) {
                  if (option.value === oldNode.value) {
                    option.setAttribute("selected", "");
                  } else {
                    option.removeAttribute("selected");
                  }
                }
              } else {
                /* eslint-disable-next-line  no-restricted-syntax */
                for (const option of newNode.options) {
                  if (option.value === newNode.value) {
                    option.setAttribute("selected", "");
                  } else {
                    option.removeAttribute("selected");
                  }
                }
              }
            }
          },
          afterNodeAdded: (element) => {
            if (element instanceof Text) { return; }
            if (element.closest("[data-dynamic-form-ignore-morph]")) { return false; }

            this.restoreDynamicFormValues.elementAdded(element);
          },
        },
      },
    );
  }
}
