import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module LoadingTextController
 * @controller
 * @private
 * @description Only to be used via the "PageLoaderComponent"
 */
export default class LoadingTextController extends Controller {
  connect() {
    this.orginalText = this.element.innerHTML;
    this.longWaitText = this.data.get("message");
    this.loadingTime = parseInt(this.data.get("time"), 10);
    this.currentText = this.orginalText;

    if (isNaN(this.loadingTime)) {
      this.loadingTime = 5000;
    }

    this.interval = setInterval(() => {
      this.updateText();
    }, this.loadingTime);
  }

  updateText() {
    this.currentText = this.currentText === this.orginalText ? this.longWaitText : this.orginalText;
    this.element.innerHTML = this.currentText;
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
