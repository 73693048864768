import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

const ACTIVE_CLASS = "is-showing";

export default class Tooltip extends Controller {
  static targets = ["action", "content"];

  connect() {
    this.popperInstance = null;
  }

  create() {
    //  Popper JS tooltip, documentation
    // https://popper.js.org/docs/v2/
    this.popperInstance = createPopper(this.actionTarget, this.contentTarget, {
      placement: "right",
      flipVariations: true,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom", "right"],
          },
        },
        {
          name: "arrow",
          options: {
            element: ".fe-Tooltip-point",
            padding: 8,
          },
        },
      ],
    });
  }

  destroy() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  show() {
    this.contentTarget.classList.add(ACTIVE_CLASS);
    this.create();
  }

  hide() {
    this.contentTarget.classList.remove(ACTIVE_CLASS);
    this.destroy();
  }

  disconnect() {
    this.destroy();
  }
}
