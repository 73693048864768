class DynamicFormButton {
  constructor(target) {
    self.target = target;
  }

  toButton() {
    const button = document.createElement("button");

    button.setAttribute("formaction", this.#formAction);
    button.setAttribute("data-turbo-frame", this.#turboFrame);
    // Ensure all request can accept turbo streams including get requests
    button.setAttribute("data-turbo-stream", "true");
    button.id = "dynamic-form-button";

    this.#applyFormMethodTo(button);

    return button;
  }

  #applyFormMethodTo(button) {
    if (!this.#formMethod) { return; }

    if (this.#formMethod === "get") {
      button.setAttribute("formmethod", "get");
    } else {
      button.setAttribute("formmethod", "post");
    }
    button.setAttribute("name", "_method");
    button.setAttribute("value", this.#formMethod);
  }

  get #formAction() {
    let urlString = window.location.href;
    if (self.target.hasAttribute("data-dynamic-form-action")) {
      urlString = self.target.getAttribute("data-dynamic-form-action");
    }

    const url = new URL(urlString, window.location.origin);
    url.searchParams.set("dynamic_form_trigger", self.target.id);
    return url.toString();
  }

  get #turboFrame() {
    if (self.target.hasAttribute("data-dynamic-form-turbo-frame")) {
      return self.target.getAttribute("data-dynamic-form-turbo-frame");
    }

    return "_self";
  }

  get #formMethod() {
    if (!self.target.hasAttribute("data-dynamic-form-method")) { return; }

    return self.target.getAttribute("data-dynamic-form-method").toLowerCase();
  }
}

const dynamicFormButton = (target) => {
  return new DynamicFormButton(target).toButton();
};

export default dynamicFormButton;
