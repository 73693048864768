import apiFetch from "shared/api_fetch";
import { merge } from "lodash";
import formatCurrencyByLocale, { CURRENCY_CONFIG } from "shared/currency/format";
import Cookies from "js-cookie";
import { createDisplayValue } from "../../../shared/currency/currencies";
import { TOOLTIP_FORMAT_TITLE_TIMESTAMP, TOOLTIP_FORMAT_CURRENCY_NO_LABEL } from "./core/chart_tooltip_formats";
import LineChartController from "./core/line_chart_controller";
import DEFAULT_CHART_OPTIONS from "./core/chart_config";
import { formatLegacyBankingChartData } from "./core/chart_data_formatting";

const COOKIE_KEY = "overview-balance-history-selected-bank-account-id";

const BANK_ACCOUNT_BALANCE_CHART_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      intersect: false,
      mode: "index",
      displayColors: false,
      bodyFont: {
        weight: "bold",
        size: 16,
      },
      callbacks: {
        title: TOOLTIP_FORMAT_TITLE_TIMESTAMP,
        label: TOOLTIP_FORMAT_CURRENCY_NO_LABEL,
      },
    },
    // requires chartjs-plugin-mouse-line
    mouseLine: {
      enabled: true,
    },
  },
  hover: {
    intersect: false,
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "month",
      },
      gridLines: {
        display: true,
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        autoSkipPadding: 8,
      },
    },
    y: {
      grid: {
        z: 1,
      },
      ticks: {
        maxTicksLimit: 5,
        // callback set below
        // BANK_ACCOUNT_BALANCE_Y_SCALE_FORMATTING
      },
    },
  },
};

const COMPRESSED_CHART_VARIATION = {
  scales: {
    x: {
      offset: false,
      precision: 0,
    },
    y: {
      ticks: {
        maxTicksLimit: 4,
      },
    },
  },
};
export default class BankAccountBalanceChartController extends LineChartController {
  static targets = [
    "logo",
    "name",
    "updated",
    "balance",
    "forApprovalCount",
    "unexplainedCount",
  ];

  async connect() {
    super.connect();
    this.balanceHistoryCache = {};
    this.accountCurrencyCode = CURRENCY_CONFIG.currency;

    // When this is consumed by ChartJS the context of `this` changes to the chart.
    // Not a global variable to preserve the ability to read an account ISO
    const BANK_ACCOUNT_BALANCE_Y_SCALE_FORMATTING = {
      plugins: {
        tooltip: {
          callbacks: {
            label: context => TOOLTIP_FORMAT_CURRENCY_NO_LABEL(context, this.accountCurrencyCode),
          },
        },
      },
      scales: {
        y: {
          grace: "500",
          ticks: {
            callback: value => formatCurrencyByLocale({
              amount: value,
              thousands: true,
              currency: this.accountCurrencyCode,
            }),
          },
        },
      },
    };

    this.chartOptions = merge({},
      DEFAULT_CHART_OPTIONS,
      BANK_ACCOUNT_BALANCE_CHART_OPTIONS,
      BANK_ACCOUNT_BALANCE_Y_SCALE_FORMATTING,
      this.element.dataset.chartSize === "compressed" ? COMPRESSED_CHART_VARIATION : {},
    );

    Promise.resolve().then(() => {
      if (this.element.hasAttribute("data-chart-bank-account")) {
        this.data.set("account", this.element.getAttribute("data-chart-bank-account"));
      }
      if (this.element.hasAttribute("data-chart-series")) {
        this.data.set("series", this.element.getAttribute("data-chart-series"));
      }

      this.updateChartOptions(this.chartOptions);
      this.updateCharts();
    });
  }

  async updateCharts() {
    const accountData = await this.getAccountData(this.selectedAccount);
    this.setDisplayData(accountData);
    this.updateChartMeta();
    this.updateChartData(this.currentDisplayData);
  }

  async getAccountData(series) {
    const seriesDataFromCache = this.balanceHistoryCache[series];
    if (seriesDataFromCache) {
      return seriesDataFromCache;
    }
    const seriesData = await this.fetchBalanceHistory(series);
    this.balanceHistoryCache[this.selectedSeries] = seriesData;
    return seriesData;
  }

  setDisplayData(accountData) {
    const series = this.selectedSeries;
    const account = this.selectedAccount;
    this.currentAccount = accountData[account];
    this.currentDisplayData = JSON.parse(JSON.stringify(accountData[account][series]));
    this.accountCurrencyCode = this.currentAccount.meta.currency.iso_code;
  }

  async fetchBalanceHistory(account) {
    const response = await apiFetch(`/bank_accounts/${account}/balance_history.js`, {}, { "X-Requested-With": "XMLHttpRequest" });
    return formatLegacyBankingChartData(response.body);
  }

  get selectedSeries() {
    return this.data.get("series") || "monthly";
  }

  get selectedAccount() {
    return this.data.get("account") || "all";
  }

  async setBankAccount(event) {
    this.data.set("account", event.target.value);
    Cookies.set(COOKIE_KEY, this.selectedAccount);
    this.updateCharts();
  }

  async setSeries(event) {
    this.data.set("series", event.target.value);
    this.updateCharts();
  }

  updateChartOptions(chartOptions) {
    super.updateChartOptions(chartOptions);
  }

  updateChartData(displayData) {
    super.updateChartData(displayData);
  }

  updateChartMeta() {
    if (this.hasLogoTarget) this.logoTarget.innerHTML = this.currentAccount.meta.logo;
    if (this.hasNameTarget) {
      this.nameTarget.href = this.currentAccount.meta.link;
      this.nameTarget.innerHTML = this.currentAccount.meta.name;
    }
    if (this.hasUpdatedTarget) this.updatedTarget.innerHTML = this.currentAccount.meta.updated;
    if (this.hasBalanceTarget) {
      this.balanceTarget.innerHTML = createDisplayValue(
        this.currentAccount.meta.balance,
        this.currentAccount.meta.currency.iso_code,
        0);
    }
    if (this.hasForApprovalCountTarget) {
      this.forApprovalCountTarget.innerHTML = this.currentAccount.meta.forApprovalCount;
    }
    if (this.hasUnexplainedCountTarget) {
      this.unexplainedCountTarget.innerHTML = this.currentAccount.meta.unexplainedCount;
    }
  }
}
