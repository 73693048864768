import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.form
 * @module CountrySelectController
 * @controller
 * @property {target} countrySelect - HTML select element containing list of countries
 * @property {target} townLabal - HTML label element for town input
 * @property {target} postcodeLabel - HTML label element for postcode input
 * @property {target} regionSelect - HTML select element containing list of regions
 * @property {target} regionInput - HTML input element for inputing region
 *
 * @description update the form to display localised form for companies from the USA
 *
 * @example
 * <form data-controller="form--country-select">
 *   <label data-testid="townLabel" data-form--country-select-target="townLabel">Town</label>
 *   <label
 *     data-testid="postcodeLabel"
 *     data-form--country-select-target="postcodeLabel"
 *   >Post/Zip Code</label>
 *   <input data-testid="regionInput" data-form--country-select-target="regionInput">
 *   <select data-testid="regionSelect" data-form--country-select-target="regionSelect"></select>
 *   <label>Country</label>
 *   <select
 *     data-testid="countrySelect"
 *     data-form--country-select-target="countrySelect"
 *     data-action="change->form--country-select#countryChanged"
 *   >
 *     <option value="France">France</option>
 *     <option value="United States" selected="selected">United States</option>
 *   </select>
 * </form>
 */
export default class CountrySelectController extends Controller {
  static targets = ["countrySelect", "townLabel", "postcodeLabel", "regionSelect", "regionInput"];

  connect() {
    this.updateForm();
  }

  /**
   * @description Triggers form update when country is changed
   *
   * @param event Event raised by action
   *
   * @example
   * <select data-testid="countrySelect"
   *   data-form--country-select-target="countrySelect"
   *   data-action="change->form--country-select#countryChanged"
   * >
   *   <option value="France">France</option>
   *   <option value="United States" selected="selected">United States</option>
   * </select>
   */
  countryChanged() {
    this.updateForm();
  }

  countrySelectTargetConnected() {
    this.updateForm();
  }

  updateForm() {
    if (!this.hasCountrySelectTarget) { return; }

    if (this.countrySelectTarget.value === "United States") {
      this.townLabelTarget.textContent = "City";
      this.postcodeLabelTarget.textContent = "Zip Code";

      this.regionSelectTarget.classList.remove("fe-u-hidden");
      this.regionSelectTarget.disabled = false;
      this.regionInputTarget.classList.add("fe-u-hidden");
      this.regionInputTarget.disabled = true;
    } else {
      this.townLabelTarget.textContent = "Town";
      this.postcodeLabelTarget.textContent = "Post/Zip Code";

      this.regionSelectTarget.classList.add("fe-u-hidden");
      this.regionSelectTarget.disabled = true;
      this.regionInputTarget.classList.remove("fe-u-hidden");
      this.regionInputTarget.disabled = false;
    }
  }
}
